import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = () => {
  const lang = "en"

  useEffect(() => {
    document.getElementsByClassName("footer")[0].style.display = "none"
  }, [])

  return (
    <Layout lang={lang} translationPL="/pl/" translationFO="/fo/">
      <SEO title="404: Not found" />
      <section className="error-page">
        <div className="ops-container">
          <img
            src={require("../../assets/images/ops.png")}
            className="ops-container__img"
            alt=""
          />
          <p className="error-page__ops">OOPS!</p>
        </div>
        <h1 className="error-page__title">There seems to be a problem...</h1>
        <p className="error-page__question">
          Would you like to go to the main page?
        </p>
        <Link className="btn btn--small" to="/">
          GO TO THE MAIN PAGE
        </Link>
      </section>
    </Layout>
  )
}

export default NotFoundPage
